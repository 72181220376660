import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import logo from "../assets/miviaje.png";
import avion from "../assets/avion.png";
import avionhorizontal from "../assets/avionhorizontal.png";

import buttonGrupoTransporte from "../assets/Buttons/buttonGrupoTransporte.png";
import buttonGrupoTransporteNoShadow from "../assets/Buttons/buttonGrupoTransporteNoShadow.png";
import buttonCompaneros from "../assets/Buttons/buttonCompaneros.png";
import buttonCompanerosNoShadow from "../assets/Buttons/buttonCompanerosNoShadow.png";
import buttonVuelos from "../assets/Buttons/buttonVuelos.png";
import buttonVuelosNoShadow from "../assets/Buttons/buttonVuelosNoShadow.png";

import hotel from "../assets/hotel.png";
import Slider from "react-slick";
import Carousel from "react-bootstrap/Carousel";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//maps
import hotel1 from "../assets/Hotel/Hotel1.png";
import hotel2 from "../assets/Hotel/Hotel2.png";
import hotel3 from "../assets/Hotel/Hotel3.png";
import hotel4 from "../assets/Hotel/Hotel4.png";

import "./dashboard.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { miviaje } from "../Api";
import { PlayCircleFilledWhiteRounded } from "@material-ui/icons";

export default function MiViaje(props) {
  const { state } = useLocation();
  const [user, setUser] = useState(null);
  const [dataVuelo, setDataVuelo] = useState(null);
  const [all, setAll] = useState(null);

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);
    //alert(window.innerWidth)
  }
  useEffect(() => {
    if (props.user) {
      setUser(props.user);
      _miviaje(props.user.email);
      _all();
    } else {
      setUser(reactLocalStorage.getObject("user"));
      _miviaje(reactLocalStorage.getObject("user").email);
      // _miviaje("ricardo.valdovinos@wearecontactica.com");

      _all();
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const _miviaje = (email) => {
    fetch(
      "https://contacticatakesmedellin.wearecontactica.com/api/index.php?email=" +
      email
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDataVuelo(data);
        // console.log(JSON.stringify(data));
      });
  };

  let _all = () => {
    fetch(
      "https://contacticatakesmedellin.wearecontactica.com/api/index.php?users=all"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setAll(data);
        // console.log("ALL" + JSON.stringify(data));
      });
  };

  const [selectButtonItem, setSelectButtonItem] = useState(0);
  function SelectButton(selectButton) {
    // console.log(selectButton);
    selectButton == selectButtonItem
      ? setSelectButtonItem(0)
      : setSelectButtonItem(selectButton);
  }

  const Cards = () => (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 10,
        flexDirection: "row",
        marginTop: 10,
      }}
    >
      <div>
        <Tabs
          className="infoTabs"
          defaultActiveKey="ida"
          id="uncontrolled-tab-example"
        >
          <Tab
            style={{
              backgroundColor: "#D9D9D9",
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              paddingBottom: 10,
            }}
            eventKey="ida"
            title="Vuelo de Ida"
          >
            <div
              style={{
                display: "flex",
                width: "90%",
                flex: 1,
                justifyContent: "space-between",
                flexDirection: widthScreen < 990 ? "column" : "row",
                alignItems: "center",
                paddingInline: 20,
                paddingTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: "../assets/miviaje.png",
                }}
              >
                <img
                  style={{ width: 29.77, height: 31.65 }}
                  src={avion}
                  alt="Logo"
                />
                <div style={{ fontWeight: "bold" }}>Fecha Ida</div>
                <div style={{}}>
                  {": "}
                  {dataVuelo &&
                    dataVuelo["0"].idaDate &&
                    dataVuelo["0"].idaDate.toUpperCase()}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Código Reserva</div>
                <div>
                  {": "}
                  {dataVuelo &&
                    dataVuelo["0"].codigoReserva &&
                    dataVuelo["0"].codigoReserva.toUpperCase()}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                paddingInline: 20,
                marginBottom: 15,
              }}
            >
              <div
                style={{ width: "100%", height: 2, backgroundColor: "#7E7E7D" }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                flexDirection: widthScreen < 990 ? "column" : "row",
                paddingInline: 20,
              }}
            >
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", fontWeight: "bold" }}>
                    Aerolinea
                  </div>
                  <div style={{ display: "flex", flex: 1 }}>
                    {": "}
                    {(dataVuelo && dataVuelo.vuelo.idaSalida.flight.company) ||
                      "Aerolinea no encontrada"}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", fontWeight: "bold" }}>
                    Itinerario
                  </div>
                  <div style={{ display: "flex", flex: 1 }}>
                    {": "}
                    {dataVuelo &&
                      dataVuelo["0"].idaItinerario
                        .split("|")
                        .map(function (item, key) {
                          return (
                            <>
                              {item}
                              <br />
                            </>
                          );
                        })}
                  </div>
                </div>
              </div>
              <div
                className="infoVuelo"
                style={{
                  display: "flex",
                  flex: 2,
                  backgroundColor: "#EEEEEE",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="vuelo">
                    {(dataVuelo && dataVuelo.vuelo.idaSalida.flight.number) ||
                      "Loading..."}
                  </div>
                  <div className="hora">
                    {(dataVuelo &&
                      dataVuelo.vuelo.idaSalida.hour.scheduled.split(" ")[0]) ||
                      "Loading..."}
                  </div>
                  <div className="codigo">
                    {(dataVuelo && dataVuelo.vuelo.idaSalida.code) ||
                      "Loading..."}
                  </div>
                  <div className="ciudad">
                    {(dataVuelo && dataVuelo.vuelo.idaSalida.name) ||
                      "Loading..."}
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      width: 29.77,
                      height: 31.65,
                    }}
                    src={avionhorizontal}
                    alt="Logo"
                  />
                </div>
                <div>
                  <div className="vuelo">
                    {(dataVuelo && dataVuelo.vuelo.idaLlegada.flight.number) ||
                      "Loading..."}
                  </div>
                  <div className="hora">
                    {(dataVuelo &&
                      dataVuelo.vuelo.idaLlegada.hour.scheduled.split(
                        " "
                      )[0]) ||
                      "Loading..."}
                  </div>
                  <div className="codigo">
                    {(dataVuelo && dataVuelo.vuelo.idaLlegada.code) ||
                      "Loading..."}
                  </div>
                  <div className="ciudad">
                    {(dataVuelo && dataVuelo.vuelo.idaLlegada.name) ||
                      "Loading..."}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: widthScreen < 990 ? "column" : "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 20,
                paddingInline: widthScreen < 990 ? 5 : 20,
              }}
            >
              <img
                onClick={() => SelectButton(1)}
                style={{ height: selectButtonItem == 1 && 52 }}
                src={
                  selectButtonItem == 1
                    ? buttonGrupoTransporteNoShadow
                    : buttonGrupoTransporte
                }
                alt="Logo"
              />
              <img
                onClick={() => SelectButton(2)}
                style={{ height: selectButtonItem == 2 && 52 }}
                src={
                  selectButtonItem == 2
                    ? buttonCompanerosNoShadow
                    : buttonCompaneros
                }
                alt="Logo"
              />
              <img
                onClick={() => SelectButton(3)}
                style={{ height: selectButtonItem == 3 && 52 }}
                src={
                  selectButtonItem == 3 ? buttonVuelosNoShadow : buttonVuelos
                }
                alt="Logo"
              />
            </div>
            {dataVuelo && selectButtonItem == 1 ? (
              <>
                {dataVuelo[0].grupoTransporte != 0 ? (
                  <h5 className="tableTitle">
                    Grupo de transporte número {dataVuelo[0].grupoTransporte}
                  </h5>
                ) : (
                  <h5 className="tableTitle">Llega antes</h5>
                )}

                {dataVuelo[0].grupoTransporte != 0 ? (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Nombre</Th>
                        <Th>Whatsapp</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataVuelo.grupoTransporte &&
                        dataVuelo.grupoTransporte.map((val, key) => {
                          return (
                            <Tr>
                              <Td>{val.nombre}</Td>
                              <Td>
                                <a
                                  href={"http://wa.me/" + val.whatsapp}
                                  target="_blank"
                                  className="link" rel="noreferrer"
                                >
                                  {val.whatsapp}
                                </a>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                ) : (
                  ""
                )}
              </>
            ) : null}
            {dataVuelo && selectButtonItem == 2 ? (
              <>
                {dataVuelo.partnersIdaFlight ? (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Nombre</Th>
                        <Th>Whatsapp</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataVuelo.partnersIdaFlight &&
                        dataVuelo.partnersIdaFlight.map((val, key) => {
                          return (
                            <Tr>
                              <Td>{val.nombre}</Td>
                              <Td>
                                <a
                                  href={"http://wa.me/" + val.whatsapp}
                                  target="_blank"
                                  className="link" rel="noreferrer"
                                >
                                  {val.whatsapp}
                                </a>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                ) : (
                  <h5 className="tableTitle">No tienes compañeros de vuelo</h5>
                )}
              </>
            ) : null}

            {all && selectButtonItem == 3 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Nombre</Th>
                    <Th>Whatsapp</Th>
                    <Th>Fecha Ida</Th>
                    <Th>Vuelo Itinerario</Th>
                    <Th>Código de reserva</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {all.map((val, key) => {
                    return (
                      <Tr>
                        <Td>{val.nombre}</Td>
                        <Td>
                          <a
                            href={"http://wa.me/" + val.whatsapp}
                            target="_blank"
                            className="link" rel="noreferrer"
                          >
                            {val.whatsapp}
                          </a>
                        </Td>
                        <Td>{val.idaDate}</Td>
                        <Td>
                          {val.idaItinerario
                            .split("|")
                            .map(function (item, key) {
                              return (
                                <>
                                  {item}
                                  <br />
                                </>
                              );
                            })}
                        </Td>
                        <Td>{val.codigoReserva}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : null}
          </Tab>
          <Tab
            style={{
              backgroundColor: "#D9D9D9",
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              paddingBottom: 10,
            }}
            eventKey="vuelta"
            title="Vuelo de Regreso"
          >
            <div
              style={{
                display: "flex",
                width: "90%",
                flex: 1,
                justifyContent: "space-between",
                flexDirection: widthScreen < 990 ? "column" : "row",
                alignItems: "center",
                paddingInline: 20,
                paddingTop: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: 29.77, height: 31.65 }}
                  src={avion}
                  alt="Logo"
                />
                <div style={{ fontWeight: "bold" }}>Fecha Regreso</div>
                <div style={{}}>
                  {": "}
                  {dataVuelo &&
                    dataVuelo["0"].regresoDate &&
                    dataVuelo["0"].regresoDate.toUpperCase()}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ fontWeight: "bold" }}>Código Reserva</div>
                <div>
                  {": "}
                  {dataVuelo &&
                    dataVuelo["0"].codigoReserva &&
                    dataVuelo["0"].codigoReserva.toUpperCase()}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                paddingInline: 20,
                marginBottom: 15,
              }}
            >
              <div
                style={{ width: "100%", height: 2, backgroundColor: "#7E7E7D" }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                flexDirection: widthScreen < 990 ? "column" : "row",
                paddingInline: 20,
              }}
            >
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", fontWeight: "bold" }}>
                    Aerolinea
                  </div>
                  <div style={{ display: "flex", flex: 1 }}>
                    {": "}
                    {(dataVuelo &&
                      dataVuelo.vuelo.regresoSalida.flight.company &&
                      dataVuelo.vuelo.regresoSalida.flight.company) ||
                      "Aerolinea no encontrada"}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", fontWeight: "bold" }}>
                    Itinerario
                  </div>
                  <div style={{ display: "flex", flex: 1 }}>
                    {": "}
                    {dataVuelo &&
                      dataVuelo["0"].regresoItinerario
                        .split("|")
                        .map(function (item, key) {
                          return (
                            <>
                              {item}
                              <br />
                            </>
                          );
                        })}
                  </div>
                </div>
              </div>
              <div
                className="infoVuelo"
                style={{
                  display: "flex",
                  flex: 2,
                  backgroundColor: "#EEEEEE",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="vuelo">
                    {(dataVuelo &&
                      dataVuelo.vuelo.regresoSalida.flight.number) ||
                      "Numero vuelo no disponible"}
                  </div>
                  <div className="hora">
                    {(dataVuelo &&
                      dataVuelo.vuelo.regresoSalida.hour.scheduled.split(
                        " "
                      )[0]) ||
                      "Hora no disponible"}
                  </div>
                  <div className="codigo">
                    {(dataVuelo && dataVuelo.vuelo.regresoSalida.code) ||
                      "Codigo no disponible"}
                  </div>
                  <div className="ciudad">
                    {(dataVuelo && dataVuelo.vuelo.regresoSalida.name) ||
                      "Nombre ciudad no disponible"}
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      width: 29.77,
                      height: 31.65,
                    }}
                    src={avionhorizontal}
                    alt="Logo"
                  />
                </div>
                <div>
                  <div className="vuelo">
                    {(dataVuelo &&
                      dataVuelo.vuelo.regresoLlegada.flight.number) ||
                      "Numero vuelo no disponible"}
                  </div>
                  <div className="hora">
                    {(dataVuelo &&
                      dataVuelo.vuelo.regresoLlegada.hour.scheduled.split(
                        " "
                      )[0]) ||
                      "Hora no disponible"}
                  </div>
                  <div className="codigo">
                    {(dataVuelo && dataVuelo.vuelo.regresoLlegada.code) ||
                      "Codigo no disponible"}
                  </div>
                  <div className="ciudad">
                    {(dataVuelo && dataVuelo.vuelo.regresoLlegada.name) ||
                      "Nombre ciudad no disponible"}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: widthScreen < 990 ? "column" : "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: 20,
                paddingInline: widthScreen < 990 ? 5 : 20,
              }}
            >
              <img
                onClick={() => SelectButton(1)}
                style={{ height: selectButtonItem == 1 && 52 }}
                src={
                  selectButtonItem == 1
                    ? buttonGrupoTransporteNoShadow
                    : buttonGrupoTransporte
                }
                alt="Logo"
              />
              <img
                onClick={() => SelectButton(2)}
                style={{ height: selectButtonItem == 2 && 52 }}
                src={
                  selectButtonItem == 2
                    ? buttonCompanerosNoShadow
                    : buttonCompaneros
                }
                alt="Logo"
              />
              <img
                onClick={() => SelectButton(3)}
                style={{ height: selectButtonItem == 3 && 52 }}
                src={
                  selectButtonItem == 3 ? buttonVuelosNoShadow : buttonVuelos
                }
                alt="Logo"
              />
            </div>
            {dataVuelo && selectButtonItem == 1 ? (
              <>
                {dataVuelo[0].grupoTransporteRegreso != 0 ? (
                  <h5 className="tableTitle">
                    Grupo de transporte número{" "}
                    {dataVuelo[0].grupoTransporteRegreso}
                  </h5>
                ) : (
                  <h5 className="tableTitle">
                    Viajas antes o despues de la fecha
                  </h5>
                )}
                {dataVuelo[0].grupoTransporteRegreso != 0 ? (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Nombre</Th>
                        <Th>Whatsapp</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataVuelo.grupoTransporteRegreso &&
                        dataVuelo.grupoTransporteRegreso.map((val, key) => {
                          return (
                            <Tr>
                              <Td>{val.nombre}</Td>
                              <Td>
                                <a
                                  href={"http://wa.me/" + val.whatsapp}
                                  target="_blank"
                                  className="link" rel="noreferrer"
                                >
                                  {val.whatsapp}
                                </a>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                ) : (
                  ""
                )}
              </>
            ) : null}
            {dataVuelo && selectButtonItem == 2 ? (
              <>
                {dataVuelo.partnersRegresoFlight ? (
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Nombre</Th>
                        <Th>Whatsapp</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataVuelo.partnersRegresoFlight &&
                        dataVuelo.partnersRegresoFlight.map((val, key) => {
                          return (
                            <Tr>
                              <Td>{val.nombre}</Td>
                              <Td>
                                <a
                                  href={"http://wa.me/" + val.whatsapp}
                                  target="_blank"
                                  className="link" rel="noreferrer"
                                >
                                  {val.whatsapp}
                                </a>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                ) : (
                  <h5 className="tableTitle">No tienes compañeros de vuelo</h5>
                )}
              </>
            ) : null}
            {all && selectButtonItem == 3 ? (
              <Table>
                <Thead>
                  <Tr>
                    <Th>Nombre</Th>
                    <Th>Whatsapp</Th>
                    <Th>Fecha Regreso</Th>
                    <Th>Vuelo Itinerario</Th>
                    <Th>Código de reserva</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {all.map((val, key) => {
                    return (
                      <Tr>
                        <Td>{val.nombre}</Td>
                        <Td>
                          <a
                            href={"http://wa.me/" + val.whatsapp}
                            target="_blank"
                            className="link" rel="noreferrer"
                          >
                            {val.whatsapp}
                          </a>
                        </Td>
                        <Td>{val.regresoDate}</Td>
                        <Td>
                          {val.regresoItinerario
                            .split("|")
                            .map(function (item, key) {
                              return (
                                <>
                                  {item}
                                  <br />
                                </>
                              );
                            })}
                        </Td>
                        <Td>{val.codigoReserva}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : null}
          </Tab>
        </Tabs>
      </div>
    </div>
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 40,
      }}
    >
      <div className="header miViajeHeader">
        <div className="container">
          <div className="title">
            <h2>CONTÁCTICA TAKES MEDELLÍN</h2>
            <h3>MI VIAJE</h3>
          </div>
        </div>
      </div>

      <div className="container">
        {user && (
          <div
            style={{
              width: "100%",
              textAlign: "start",
              marginTop: 20,
              fontWeight: "bold",
              fontSize: "1.8rem",
            }}
          >
            <div style={{}}>
              {dataVuelo && dataVuelo["0"].nombre.toUpperCase()}
            </div>
          </div>
        )}
        <div
          style={{ width: "100%", height: 2, backgroundColor: "black" }}
        ></div>
        <div
          className="Montserrat infoVueloTitle"
          style={{ width: "100%", textAlign: "start", marginTop: 40 }}
        >
          MI INFORMACIÓN DE VUELO
        </div>
        {/* <div className="alert alert-danger alertViaje">
          La información de vuelo es una referencia y puede cambiar en cualquier
          momento, por favor valida muy bien tu código de reserva y recuerda
          estar atentx a los cambios que pueden efectuar las aerolíneas.
          Cualquier duda adicional que tengas puedes comunicarte con Ricardo
          Valdovinos (
          <a href="mailto:ricardo.valdovinos@wearecontactica.com">
            ricardo.valdovinos@wearecontactica.com
          </a>
          ) o María Zafra (
          <a href="mailto:maria.zafra@wearecontactica.com">
            maria.zafra@wearecontactica.com
          </a>
          ).
        </div> */}
        {Cards()}
      </div>
      <div className="container wework pt-5 pb-2">
        <div className="row">
          <div className="col-md-12">
            <h4>MI INFORMACIÓN DE HOTEL</h4>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-7">
                <Slider {...settings}>
                  <img src={hotel1} alt="Logo" />
                  <img src={hotel2} alt="Logo" />
                  <img src={hotel3} alt="Logo" />
                  <img src={hotel4} alt="Logo" />
                </Slider>
              </div>
              <div className="col-md-5 mt-md-0 mt-4">
                <h3>ESTELAR MILLA DE ORO</h3>
                <div
                  className={
                    dataVuelo &&
                      dataVuelo.hotelRoom.type &&
                      dataVuelo.hotelRoom.type == "Individual"
                      ? "iconCamaSencilla"
                      : "iconCamaDoble"
                  }
                >
                  Tipo de Habitación:
                  <br />
                  <strong>
                    {dataVuelo && dataVuelo.hotelRoom.type
                      ? dataVuelo.hotelRoom.type
                      : "No tiene"}
                  </strong>
                </div>

                {dataVuelo && dataVuelo.hotelRoom.partner ? (
                  <div className="iconPerson">
                    Compañero de Habitación:
                    <br />
                    <strong>{dataVuelo.hotelRoom.partner}</strong>
                  </div>
                ) : (
                  ""
                )}

                <div className="iconFood">
                  Horario Desayuno:
                  <br />
                  <strong>6:00 AM - 10:00 AM</strong>
                </div>
                <div className="iconDoor">
                  Check Out:
                  <br />
                  <strong>1:00 PM</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
