import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import logo from "../assets/infoviaje.png";
import requisitos from "../assets/requisitos.png";
import vacunas from "../assets/vacunas.png";
import warning from "../assets/warning.png";
import buttonCartaInvitacion from "../assets/buttonCartaInvitacion.png";
import buttonReservaHotel from "../assets/buttonReservaHotel.png";
import "./dashboard.css";

import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

export default function MiViaje() {
  const classes = useStyles();

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);

    document.getElementsByClassName("content")[1].style.height = `${document.getElementsByClassName("content")[0].clientHeight
      }px`;

    //alert(window.innerWidth)
  }
  useEffect(() => {
    document.getElementsByClassName("content")[1].style.height = `${document.getElementsByClassName("content")[0].clientHeight
      }px`;
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 40,
      }}
    >
      <div className="header infoViajeHeader">
        <div className="container">
          <div className="title">
            <h2>CONTÁCTICA TAKES MEDELLÍN</h2>
            <h3>INFORMACIÓN PREVIA AL VIAJE</h3>
          </div>
        </div>
      </div>

      <div className="container infoViaje">
        <div className="row">
          <div className="container mt-5">
            <div className="row mt-5">
              <div className="col-12 seguridad">
                <p>
                  Estas son sólo algunas recomendaciones,
                  <strong>
                    {""} por favor revisa con cuidado las especificaciones de su país
                  </strong>{" "}
                  al momento de realizar el check-in.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row cards">
          <div className="col-md-6">
            <div className="content">
              <img
                style={{ width: 146, height: 146, marginTop: 20 }}
                src={requisitos}
                alt="Requisitos Generales"
              />
              <h3>REQUISITOS GENERALES</h3>
              <ul>
                <li>Pasaporte vigente</li>
                <li>
                  Diligenciar a través del siguiente link
                  el formulario de Migraciones <a href="https://apps.migracioncolombia.gov.co/
                  pre-registro/">https://apps.migracioncolombia.gov.co/
                    pre-registro/</a>
                </li>
                <li>Presentar boleto de avión de salida
                  de Colombia</li>
                <li>Comprobar el motivo de su viaje, mediante los siguientes documentos:</li>
              </ul>
              <img
                onClick={() =>
                  window.open(
                    "https://contacticatakesmedellin.wearecontactica.com/docs/carta/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  )
                }
                src={buttonCartaInvitacion}
                alt="Logo"
              />
              {/* <img
                onClick={() =>
                  window.open(
                    "https://contacticatakesmedellin.wearecontactica.com/docs/hotel/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  )
                }
                src={buttonReservaHotel}
                alt="Logo"
              /> */}
              <img
                onClick={() =>
                  window.open(
                    "https://contacticatakesmedellin.wearecontactica.com/docs/hotel/reserva.pdf"
                  )
                }
                src={buttonReservaHotel}
                alt="Logo"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content">
              <img
                style={{ width: 146, height: 146, marginTop: 20 }}
                src={vacunas}
                alt="Vacunas"
              />
              <h3>VACUNAS</h3>
              <ul>
                <li>
                  Actualmente Colombia no requiere vacuna contra COVID, prueba PCR ni cuarentena obligatoria.
                </li>
                <li>
                  Sin embargo, te recomendamos llevar tu carnet de vacunación ya que lo pueden solicitar al hacer migración.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid infoViajePaises p-5">
        <div className="container">
          <div>
            <h5>ARGENTINA</h5>
            <ul>
              <li>
                Nuevo DNI o pasaporte vigente (preferentemente usar pasaporte por tema de escalas)
              </li>
            </ul>
          </div>
          <div>
            <h5>EL SALVADOR</h5>
            <ul>
              <li>
                Ciudadanos salvadoreños que viajan a los países de riesgo (Colombia) deben vacunarse contra la fiebre amarilla y llevar con ellos el “Certificado Internacional de Vacunación contra la Fiebre Amarilla, CIV”, el cual debe ser presentado en original, ser válido y estar vigente. El certificado será válido diez (10) días después de aplicada la vacuna y tendrá una vigencia de por vida.
              </li>
            </ul>
          </div>
          <div>
            <h5>TODOS LOS PAÍSES</h5>
            <ul>
              <li>
                Llevar la siguiente documentación impresa y a la mano: carta invitación, información del hotel, tiquete de ida y regreso, comprobante de formulario de Migraciones realizado.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  cardVertical: {
    minWidth: 275,
    borderRadius: 15,
    border: `2px solid #333332`,
  },
  cardHorizontal: {
    minWidth: 275,
    borderRadius: 15,
    border: `2px solid #E71E34`,
  },
});
