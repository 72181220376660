import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";

export default function App() {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(null);

  const cerrarSesion = () => {
    reactLocalStorage.clear();
    setLogin(false);
  };

  const iniciarSesion = (props) => {
    reactLocalStorage.setObject("user", props);
    reactLocalStorage.set("login", true);
    setLogin(true);
    setUser(props);
  };
  // navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY >= 30) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    setUser(reactLocalStorage.getObject("user"));
    setLogin(reactLocalStorage.get("login", true));
  }, []);

  const setNavbar = (props) => {
    const navbar = document.getElementsByClassName("navigationTop")[0];
    props
      ? navbar.classList.add("scrolled")
      : navbar.classList.remove("scrolled");
  };

  return (
    <BrowserRouter>
      <Router
        cerrarSesion={cerrarSesion}
        iniciarSesion={iniciarSesion}
        login={login}
        user={user}
      />
    </BrowserRouter>
  );
}
