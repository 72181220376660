import React from "react";
import itinerario from "../assets/itinerario.png";
import "./dashboard.css";
import { useEffect } from "react";

export default function Intinerario() {
  useEffect(() => { }, []);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 40,
      }}
    >
      <div className="header itinerarioHeader">
        <div className="container">
          <div className="title">
            <h2>CONTÁCTICA TAKES MEDELLÍN</h2>
            <h3>ITINERARIO</h3>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>
            JUEVES 26
          </div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1 }}></div>
              <div style={{ display: "flex", flex: 1 }}>Llegada de vuelos</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                2:30 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Encuentro en We Work de Poblado para salir a Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                2:40 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida a Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                3:00 PM - 5:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                5:15 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Regreso al hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                5:30 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Preparación para Contactiwín (fiesta)
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:45 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Encuentro Lobby hotel para salir a Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                8:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida a Contactiwín todo el equipo
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                8:30 PM a 2:00 AM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 60,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>VIERNES 27</div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                6:00 AM a 10:00 AM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Desayuno en el hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                MAÑANA
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Libre
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                12:15 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Encuentro lobby hotel para salir al grafitour
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                12:20 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida al Graffitour comuna 13
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>

            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                1:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Inicio Graffitour comuna 13
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                4:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Fin Graffitour comuna 13
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                4:15 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Regreso al hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                5:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Hotel - preparación para cena cierre</div>
            </div>

            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:15 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Encuentro lobby hotel para salir a la cena de ciere
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:30 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida cena cierre todo el equipo
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                8:00 PM - 10:00PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Cena cierre Contactiwín
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                10:00 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Regreso al hotel - Libre
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 60,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>SÁBADO 28</div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                6:00 AM a 10:00 AM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Desayuno en el hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>

              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Regreso a país de origen
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
