import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import NavigationBar from "../shared/components/navigation-bar";
import avionhorizontal from "../assets/avionhorizontal.png";

export default function LandingPage(props) {
  const navigate = useNavigate();

  // const google = window.google;

  const [departmentItem, setDepartmentItem] = useState(null);

  function handleCallbackResponse(response) {
    // console.log("Encoded JWT ID token " + response.credential);
    var userObject = jwt_decode(response.credential);
    props.iniciarSesion(userObject);
    navigate("/dashboard");
  }

  useEffect(() => {
    // _department();
    /*global google*/
    setTimeout(function () {
      google.accounts.id.initialize({
        client_id:
          "390822464572-grrtepm6lomnim7e9ske41t45mf2ipa3.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("singInDiv"), {
        theme: "outline",
        size: "large",
      });

      google.accounts.id.prompt();
    }, 1000);
  }, []);

  const _department = () => {
    fetch(
      "https://contacticatakesmedellin.wearecontactica.com/api/index.php?flightsToMX=all"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // alert(JSON.stringify(data));
        setDepartmentItem(data);
        console.log("_department" + JSON.stringify(data));
      });
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundImage: `url(${"/infomedellin.png"})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <NavigationBar login={false} user={null} />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          marginTop: "40vh",
          marginLeft: "10vh",
        }}
        className="containerText"
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              width: 50,
              height: 6,
              marginBottom: 20,
              marginLeft: 10,
              backgroundColor: "#E71E34",
            }}
          ></div>
          <h5>CONTÁCTICA TAKES</h5>
          <h1>
            MEDELLÍN
          </h1>
          <div style={{ flex: 1 }} id="singInDiv"></div>
        </div>
        <div style={{ flex: 1, display: "none" }}>
          {departmentItem &&
            departmentItem.map((val, key) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flex: 2,
                    backgroundColor: "#EEEEEE",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div>{JSON.stringify(val.ida.horarios)}</div>
                    <div>a</div>
                    <div>a</div>
                  </div>
                  <div>
                    <img
                      style={{
                        justifyContent: "center",
                        textAlign: "center",
                        width: 29.77,
                        height: 31.65,
                      }}
                      src={avionhorizontal}
                      alt="Logo"
                    />
                  </div>
                  <div>
                    <div>a </div>
                    <div>a</div>
                    <div>a</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
