import React, { useEffect, useState } from "react";

import Dashboard from "./dashboard/Dashboard";
import InfoMedellin from "./dashboard/InfoMedellin";
import MiViaje from "./dashboard/MiViaje";
import InfoViaje from "./dashboard/InfoViaje";
import Intinerario from "./dashboard/Intinerario";


import NotFound from "./pages/NotFound";
import LandingPage from "./pages/LandingPage";
import SettingsAndPrivacy from "./dashboard/settings-and-privacy";
import { Route, Routes } from "react-router";

/*Routes is used to be Switch*/
export default function Router(props) {
  /* nesting routes*/

  useEffect(() => {
    if (props) {
    
      
    } else {

    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<LandingPage iniciarSesion={props.iniciarSesion} />} />
      <Route path="/dashboard" element={<Dashboard login={props.login} user={props.user} cerrarSesion={props.cerrarSesion} />}>
        <Route path="/dashboard/" element={<InfoMedellin login={props.login} user={props.user} />} />
        <Route path="/dashboard/infomedellin" element={<InfoMedellin login={props.login} user={props.user} />} />
        <Route path="/dashboard/miviaje" element={<MiViaje login={props.login} user={props.user} />} />
        <Route path="/dashboard/infoviaje" element={<InfoViaje login={props.login} user={props.user} />} />
        <Route path="/dashboard/intinerario" element={<Intinerario login={props.login} user={props.user} />} />
        <Route
          path="/dashboard/settings-and-privacy"
          element={<SettingsAndPrivacy />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
