import React, { useState } from "react";
import { useEffect } from "react";
import "./dashboard.css";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

import clima from "../assets/clima.png";
import restaurantes from "../assets/restaurantes.png";
import warning from "../assets/warning.png";

//
import Carousel from "react-grid-carousel";
import styled from "styled-components";
//

//maps
import Poblado from "../assets/Maps/MapaPoblado.png";
import Laureles from "../assets/Maps/MapaLaureles.png";
import HotelSantaFe from "../assets/Maps/Hotel-SantaFe.png";
import HotelWeWork from "../assets/Maps/Hotel-WeWork.png";



//Turisticos
import Parte1 from "../assets/Turisticos/Parte1.png";
import Parte2 from "../assets/Turisticos/Parte2.png";

//WeWork
import WeWork1 from "../assets/WeWork/WeWork1.png";
import WeWork2 from "../assets/WeWork/WeWork2.png";
import WeWork3 from "../assets/WeWork/WeWork3.png";
import WeWork4 from "../assets/WeWork/WeWork4.png";

//Delivery
import Didi from "../assets/Delivery/Didi.png";
import Rappi from "../assets/Delivery/Rappi.png";
import UberEats from "../assets/Delivery/UberEats.png";
import Uber from "../assets/Delivery/Uber.png";

//Slides
/* import Slider1 from "../assets/Alides/Slider1.png";
import Slider2 from "../assets/Alides/Slider2.png";
import Slider3 from "../assets/Alides/Slider3.png";
import Slider4 from "../assets/Alides/Slider4.png";
import Slider5 from "../assets/Alides/Slider5.png"; */

const Container = styled.div`
  min-height: 100%;
  width: 100%;
`;

const Row = styled.div`
  max-width: 1100px;
  padding: 0 50px;
  margin: 50px auto;
  @media screen and (max-width: 670px) {
    padding: 0;
  }
`;

const ArrowBtn = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  right: ${({ type }) => (type === "right" ? "-40px" : "unset")};
  left: ${({ type }) => (type === "left" ? "-40px" : "unset")};
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ type }) =>
    type === "right"
      ? "translate(-75%, -50%) rotate(45deg)"
      : "translate(-25%, -50%) rotate(-135deg)"};
    width: 10px;
    height: 10px;
    border-top: 2px solid #666;
    border-right: 2px solid #666;
  }
  &:hover::after {
    border-color: #333;
  }
`;

const Card = styled.div`
  margin: 2px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const Img = styled.div`
  height: 325px;
  margin-bottom: 4px;
  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default function InfoMedellin(props) {
  const classes = useStyles();

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  const [selectImage, setSelectImage] = useState("/infomedellin.png");
  const [selectContent, setSelectContent] = useState(0);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);
    //alert(window.innerWidth)
  }
  useEffect(() => {
    if (props) {
      //alert(JSON.stringify(props))
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [props]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ChangeContent(image, text) {
    setSelectImage(image);
    setSelectContent(text);
  }

  function DynamicContent(text) {
    switch (text) {
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              PALACIO DE
              <br />
              BELLAS ARTES
            </h1>
            <h5>
              Es el principal teatro lírico y centro de expresión
              <br />
              de las bellas artes del país.
            </h5>
          </div>
        );
        break;
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>EL ZÓCALO</h1>
            <h5>
              La Plaza de la Constitución, informalmente conocida
              <br />
              como El Zócalo, es la plaza principal de la
              <br />
              Ciudad de México
            </h5>
          </div>
        );
        break;
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              MONUMENTO A<br />
              LA REVOLUCIÓN
            </h1>
            <h5>
              Es una obra arquitectónica y un mausoleo dedicado
              <br />a la conmemoración de la Revolución mexicana.
            </h5>
          </div>
        );
        break;
      case 4:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              PIRAMIDES DE
              <br />
              TEOTIHUACÁN
            </h1>
            <h5>
              La palabra Teotihuacán traduce “lugar donde
              <br />
              los hombres se convierten en dioses”.
            </h5>
          </div>
        );
        break;
      case 5:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              CASTILLO DE <br />
              CHAPULTEPEC
            </h1>
            <h5>
              Fue el hogar de los virreyes españoles y el Emperador
              <br />
              Maximiliano de Habsburgo. Posteriormente fue
              <br />
              también Casa Presidencial hasta 1940
            </h5>
          </div>
        );
        break;
      default:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              MEDELLÍN</h1>
          </div>
        );
        break;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingBottom: 40,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundImage: `url(${selectImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        {DynamicContent(selectContent)}
        <div style={{ display: "flex", flex: 1, marginTop: "40vh" }}>
          {/* <Container>
            <Row>
              <Carousel
                interval={null}
                cols={4}
                rows={1}
                gap={11}
                responsiveLayout={[
                  {
                    breakpoint: 1200,
                    cols: 3,
                  },
                  {
                    breakpoint: 990,
                    cols: 2,
                  },
                ]}
                mobileBreakpoint={670}
                arrowRight={<ArrowBtn type="right" />}
                arrowLeft={<ArrowBtn type="left" />}
              >
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent("/Background1.png", 1)}
                      img={"/Slider1.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background2.png`, 2)}
                      img={"/Slider2.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background3.png`, 3)}
                      img={"/Slider3.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background4.png`, 4)}
                      img={"/Slider4.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background5.png`, 5)}
                      img={"/Slider5.png"}
                    />
                  </Card>
                </Carousel.Item>
              </Carousel>
            </Row>
          </Container> */}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="climaMexico col-md-12 mb-md-5 mt-md-5">
            <img
              src={clima}
              alt="Clima en México"
              style={{ display: widthScreen < 990 ? "none" : "block" }}
            />
            <div className={widthScreen < 990 ? "mobile" : null}>
              <span>¿CÓMO ES EL CLIMA EN MEDELLÍN EN OCTUBRE?</span>
              <p>
                Medellín acostumbra tener la misma temperatura durante todo el año: 17°C a 27°C (62°F a 80.6°F)
                Recomendamos que tu vestimenta sea ligera y traigas elementos como:
              </p>
              <ul>
                <li>Pantalones largos o cortos</li>
                <li>Suéters o chamarras ligeras</li>
                <li>Faldas o vestidos</li>
                <li>Sandalias o tenis</li>
                <li>Gorras y lentes de sol</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container lugaresCaminar pb-4">
        <div className="row">
          <div className="col-md-12">
            <h2>LUGARES PARA CAMINAR EN MEDELLÍN</h2>
            <div>
              <Slider {...settings}>
                <div>
                  <img src={HotelWeWork} alt="Logo" />
                  <p>Hotel - We Work</p>
                </div>
                <div>
                  <img src={HotelSantaFe} alt="Logo" />
                  <p>Hotel - Santa Fé</p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid lugaresTuristicos py-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <h2 className="mb-5 text-center">LUGARES TURÍSTICOS</h2>
            </div>
            <div className="col-md-12">
              <Slider {...settings}>
                <div>
                  <img src={Parte1} alt="Logo" />
                </div>
                <div>
                  <img src={Parte2} alt="Logo" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container wework pt-5 pb-2">
        <div className="row">
          <div className="col-md-12">
            <h2>WEWORK CERCANO</h2>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-7">
                <Slider {...settings}>
                  <img src={WeWork1} alt="Logo" />
                  <img src={WeWork2} alt="Logo" />
                  <img src={WeWork3} alt="Logo" />
                  <img src={WeWork4} alt="Logo" />
                </Slider>
              </div>
              <div className="col-md-5 mt-md-0 mt-4">
                <h3>WE WORK PARQUE DEL POBLADO</h3>
                <div className="iconLocation">
                  <strong>
                    Calle 7D # 43A-40 <br />
                    Poblado, Medellín
                  </strong>
                </div>
                <span>TRANSPORTES CERCANOS</span>
                <div className="iconMetro">
                  La estación Poblado del metro
                  está a 5 cuadras
                </div>
                <div className="iconCar">
                  Estacionamiento dentro del edificio
                </div>
                <div className="iconMap">
                  A dos cuadras del Parque del
                  Poblado, sobre la avenida del Poblado
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-md-2 infoRestaurantes" style={{ marginTop: 20, marginBottom: 20 }}>
        <img style={{ marginTop: 50 }} width="100%" src={restaurantes} alt="Logo" />
        <div className="container">
          <div className="row" style={{ margin: 40 }}>
            <div className="col-sm-2 mx-auto text-center">
              <p style={{ color: "#E71E34", fontWeight: 'bold' }}>RESTAURANTES</p>
              <p style={{ padding: 0, margin: 4 }} href="">Creppes & Waffles</p>
              <p style={{ padding: 0, margin: 4 }} href="">Parmessano</p>
              <p style={{ padding: 0, margin: 4 }} href="">El Rancherito</p>
              <p style={{ padding: 0, margin: 4 }} href="">Mundo Verde</p>
              <p style={{ padding: 0, margin: 4 }} href="">El Corral</p>
            </div>
            <div className="col-sm-2 mx-auto text-center">
              <p style={{ color: "#E71E34", fontWeight: 'bold' }}>DROGUERIAS</p>
              <p style={{ padding: 0, margin: 4 }} href="">Farmatodo </p>
              <p style={{ padding: 0, margin: 4 }} href="">Cruz Verde </p>
              <p style={{ padding: 0, margin: 4 }} href="">La Rebaja</p>
              <p style={{ padding: 0, margin: 4 }} href="">Pasteur</p>
              <p style={{ padding: 0, margin: 4 }} href="">Locatel</p>
            </div>
          </div>
        </div>
      </div>




      <div className="apps container my-4">
        <h2>APPS DELIVERY Y TRANSPORTE</h2>
        <div className="justify-content-center row">
          <div className="col-12 col-xl-10 logos mt-5">
            <img src={Didi} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={Rappi} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={UberEats} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={Uber} alt="Logo" className="col-md-3 col-6 mb-5" />
          </div>
        </div>
      </div>

      <div className="container-fluid mexicanoCercano mt-md-3 pt-5">
        <div className="container">
          <h2>TU PAISA MÁS CERCANO</h2>
          <div className="px-5 py-4 row">
            <div className="col-md-3">
              <ul>
                <li>
                  Alejandro Montoya <br />
                  <a target="_blank" href="https://wa.me/+573128921796" rel="noreferrer">
                    (+57) 312 892 1796
                  </a>
                </li>
                <li>
                  Ana Echeverry
                  <br />
                  <a target="_blank" href="https://wa.me/+573122659729" rel="noreferrer">
                    (+57) 312 265 9729{" "}
                  </a>
                </li>
                <li>
                  Ana María Flórez Uribe <br />
                  <a target="_blank" href="https://wa.me/+573017690353" rel="noreferrer">
                    (+57) 301 769 0353
                  </a>
                </li>
                <li>
                  Andrés Atehortua (ATE) <br />
                  <a target="_blank" href="https://wa.me/+573218524486" rel="noreferrer">
                    (+57) 321 852 4486
                  </a>
                </li>
                <li>
                  Carolina Lopera
                  <br />
                  <a target="_blank" href="https://wa.me/+573015295312" rel="noreferrer">
                    (+57) 301 529 5312{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li>
                  Camilo Miranda <br />
                  <a target="_blank" href="https://wa.me/+573233582769" rel="noreferrer">
                    (+57) 323 358 2769
                  </a>
                </li>
                <li>
                  Cristina Álvarez <br />
                  <a target="_blank" href="https://wa.me/+573217025437" rel="noreferrer">
                    (+57) 321 702 5437
                  </a>
                </li>
                <li>
                  Esteban Suárez Castro
                  <br />
                  <a target="_blank" href="https://wa.me/+573006145825" rel="noreferrer">
                    (+57) 300 614 5825{" "}
                  </a>
                </li>
                <li>
                  Estefania Germanetti <br />
                  <a target="_blank" href="https://wa.me/+573196394842" rel="noreferrer">
                    (+57) 319 639 4842
                  </a>
                </li>
                <li>
                  Jorge Posada (Coke) <br />
                  <a target="_blank" href="https://wa.me/+573192161265" rel="noreferrer">
                    (+57) 319 216 1265
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li>
                  Juan Esteban Montoya <br />
                  <a target="_blank" href="https://wa.me/+573117500170" rel="noreferrer">
                    (+57) 311 750 0170
                  </a>
                </li>
                <li>
                  Karen Guingue <br />
                  <a target="_blank" href="https://wa.me/+573193571508" rel="noreferrer">
                    (+57) 319 357 1508
                  </a>
                </li>
                <li>
                  Leonardo Macea <br />
                  <a target="_blank" href="https://wa.me/+573116163917" rel="noreferrer">
                    (+57) 311 616 3917
                  </a>
                </li>
                <li>
                  Manuel Mutis <br />
                  <a target="_blank" href="https://wa.me/+573148800106" rel="noreferrer">
                    (+57) 314 880 0106
                  </a>
                </li>
                <li>
                  Marvin Ávila <br />
                  <a target="_blank" href="https://wa.me/+573005709187" rel="noreferrer">
                    (+57) 300 570 9187
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <li>
                  Natalia Medina <br />
                  <a target="_blank" href="https://wa.me/+573147505428" rel="noreferrer">
                    (+57 ) 314 750 5428
                  </a>
                </li>
                <li>
                  Nicolás Quelal <br />
                  <a target="_blank" href="https://wa.me/+573117749079" rel="noreferrer">
                    (+57) 311 774 9079
                  </a>
                </li>
                <li>
                  Paola del Pilar Pacheco<br />
                  <a target="_blank" href="https://wa.me/+573004502451" rel="noreferrer">
                    (+57) 300 450 2451
                  </a>
                </li>
                <li>
                  Paula Vargas <br />
                  <a target="_blank" href="https://wa.me/+573008684164" rel="noreferrer">
                    (+57) 300 868 4164
                  </a>
                </li>
                <li>
                  Valentina Castrillón<br />
                  <a target="_blank" href="https://wa.me/+573113422614" rel="noreferrer">
                    (+57) 311 342 2614
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-12 seguridad">
            <h2>CONSEJOS DE SEGURIDAD</h2>
            <div className="row" style={{}}>
              <div className="col-md-6 gx-md-5">
                <ul>
                  <li>Es ilegal ingresar drogas a Colombia, así como la compra y venta de las mismas.</li>
                  <li>Procura no sacar tu celular en la calle.</li>
                  <li>La vida nocturna en Medellín es hasta altas horas de la noche, no te quedes ni salgas solx.</li>
                  <li>
                    Si viajas en Uber procura viajar acompañadx o en su defecto, compartir tu ubicación con alguien.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Si sales de antro/discoteca/rumba cuida tu trago y ponle la mano encima.</li>
                  <li>No recibas trago, ni nada de extraños.</li>
                  <li>
                    No ir a la comuna 13 sin guia turistico.
                  </li>
                  <li>Cuida siempre tus objetos de valor.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const useStyles = makeStyles({
  cardHorizontal: {
    minWidth: 275,
    borderRadius: 30,
    border: `2px solid #E71E34`,
  },
});
