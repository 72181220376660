import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import NavigationBar from "../shared/components/navigation-bar";
import './dashboard.css'
import { reactLocalStorage } from 'reactjs-localstorage';
import Swal from 'sweetalert2'

export default function Dashboard(props) {

  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    Swal.fire({
      title: '¡Recuerda! 😊',
      html: `<strong>10/26 ☀️:</strong> Asegúrate de tener tu Hoodie de Contáctica 🧥 <br>
            <strong>10/27 🌙:</strong> Lleva contigo una prenda de color rojo ❤️`,
      icon: 'info',
      iconColor: '#e71e34',
      confirmButtonText: 'No lo olvidare 🤓',
      width: 600,
      confirmButtonColor: '#e71e34',
    })


    if (props) {
      setUser(props.user)
      setLogin(props.login)
    } else {
      setUser(reactLocalStorage.getObject('user'))
      setLogin(reactLocalStorage.get('login', true))
    }
  }, [])




  return (
    <div className='background2'>
      <NavigationBar login={props.login} user={props.user} cerrarSesion={props.cerrarSesion} />
      <Outlet />
    </div>
  );
};

